/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.title-large {
  font-family: 'Roboto';
  font-weight: 900;
  font-size: 2rem;
  line-height: 130%;
  letter-spacing: 0em;
}

.title-medium{
  font-family: 'Roboto';
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 130%;
  letter-spacing: 0em;
}

.title-small{
  font-family: 'Roboto';
  font-weight: 900;
  font-size: 1.25rem;
  line-height: 130%;
  letter-spacing: 0em;
}

.body-medium {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 1rem;
  line-height: 150%;
  letter-spacing: 0;
}

.body-large-bold{
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 150%;
  letter-spacing: 0;
}

.body-medium-bold{
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 1rem;
  line-height: 150%;
  letter-spacing: 0;
}

.body-small-bold {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 150%;
  letter-spacing: 0;
}

.bg-broad {
  background-color: var(--primary-color) !important;
  color: white !important;
}

.bg-broad-light {
  background-color: var(--primary-light-color) !important;
}

.bg-light {
  background-color: #EBEBEB !important;
}

.bg-light-dark {
  background-color: var(--primary-white-color);
}



.primary-light-color {
  color: var(--primary-light-color) !important;
}
.primary-color {
  color: var(--primary-color) !important;
}

.second-color {
  color: var(--second-color) !important;
}

.bg-primary {
  background-color: var(--primary-color) !important;
  color: white !important;
}

.bg-primary-light {
  background-color: var(--primary-light-color) !important;
}

.bg-second {
  background-color: var(--primary-color) !important;
  color: white !important;
}

.bg-second-dark {
  background-color: var(--second-dark-color) !important;
  color: white !important;
}

.bg-light-dark-color {
  background-color: var(--light-dark-color) !important;
  color: white !important;
}

.container-fluid {
  margin-left: 0;
  margin-right: 0;
}

.light-dark-color {
  color: var(--light-dark-color) !important;
}

:root {
  --primary-color: #1B6C0E !important;
  --primary-light-color: #95BA8E!important;
  --second-color: #CC3179 !important;

  --light-dark-color: #606062!important;
  --primary-dark-color: #1E1E1E !important;
  --second-dark-color: #313131 !important;
  --primary-white-color: #F2F2F2 !important;
}
